import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useCheckout from '~hooks/useCheckout';
import products, { PRODUCT_IDS } from '~utils/products';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const ProducersCircleButton = ({ title, buttonSpacing, ...rest }) => {
  const theme = useTheme();
  const { isMarketingSite } = useConversionFramework();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [, setCheckoutState] = useCheckout();

  const handlePremiumCheckoutClick = () => {
    const product = products.find(({ id }) => id === PRODUCT_IDS.Premium);

    if (isMarketingSite) {
      redirectToMembersCheckout(product, 'year');
    } else {
      setCheckoutState({ product, billingCycle: 'year' });
      navigate('/checkout');
    }
  };

  return (
    <Box py={buttonSpacing} {...rest}>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={handlePremiumCheckoutClick}
        style={{ minWidth: '239px', minHeight: '48px', textTransform: 'none', fontSize: '18px' }}
        fullWidth={isSmallerThanSm}
      >
        {title}
      </Button>
    </Box>
  );
};

ProducersCircleButton.propTypes = {
  title: PropTypes.string.isRequired,
  buttonSpacing: PropTypes.number
};

ProducersCircleButton.defaultProps = {
  buttonSpacing: 2
};

export default ProducersCircleButton;
