import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import { lineFix } from '~modules/premium-subscription/premium-subscription.utils';
import NewsletterImage from '~images/guides/parsha-guide-header.png';
import PremiumSubscriptionSection from './components/PremiumSubscriptionSection';
import VideoThumbnailsImage from './images/subscriptions.png';
import StudyImage from './images/live-webinar.png';

const SECTIONS = [
  {
    title: 'Live Exclusive Webinars with Rabbi Fohrman and the Aleph Beta Scholars',
    image: {
      url: StudyImage,
      alt: 'Aleph Beta Study'
    },
    list: [
      'Join a community of thousands of fans and educators at these exclusive events',
      'Get answers to your specific questions',
      'Participate in engaging and inspiring discussions based on Torah lessons'
    ]
  },
  {
    title: 'Printable Parsha Guides',
    image: {
      url: NewsletterImage,
      alt: 'Aleph Beta Newsletter'
    },
    list: [
      'Come to your Shabbat table prepared',
      'Printable Parsha guides help you structure amazing experiences for your family and friends',
      'Also get access to printable Holiday guides'
    ]
  },
  {
    title: 'Choose From Hundreds Of Exclusive Courses',
    image: {
      url: VideoThumbnailsImage,
      alt: 'Aleph Beta Thumbnails'
    },
    list: [
      'Exclusive access to Aleph Beta’s most in-depth content, including audio and video series for the advanced user',
      'Weekly Parsha Podcasts',
      'Access to Bar/Bat Mitzvah Course and Materials'
    ]
  }
];

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '28px',
      lineHeight: '30px'
    }
  }
}));

const PremiumSubscriptionSections = () => {
  const classes = useStyles();
  return (
    <>
      <Container center>
        <Box py={4}>
          <Typography variant="h4" className={classes.heading}>
            {lineFix('Join Today And Get Access To These Exclusive Benefits')}
          </Typography>
        </Box>
      </Container>

      {SECTIONS &&
        SECTIONS.map((section, index) => {
          const isOdd = index % 2 !== 0;
          return (
            <PremiumSubscriptionSection
              key={section.title}
              bgColor={isOdd ? '#ffffff' : '#F6F8FB'}
              direction={isOdd ? 'row' : 'row-reverse'}
              {...section}
            />
          );
        })}
    </>
  );
};

export default PremiumSubscriptionSections;
