import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import { lineFix } from '~modules/premium-subscription/premium-subscription.utils';
import {
  PremiumSubscriptionPriceTag,
  PremiumSubscriptionCheckoutButton,
  PremiumSubscriptionNote
} from '~modules/premium-subscription/components/shared';
import MediaPlayerWithAnimatedPlayButton from '~src/components/MediaPlayerWithAnimatedPlayButton';
import PremiumLogo from './images/ab-premium-logo.png';

const useStyles = makeStyles(theme => ({
  heroImage: {
    display: 'flex',
    width: '100%',
    height: 'auto'
  },
  heroTitleText: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '40px',
    color: '#202020',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '30px',
      lineHeight: '34px'
    }
  },
  heroSubtitleText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  leftColumn: {
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  }
}));

const PremiumSubscriptionHero = () => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const classes = useStyles();

  return (
    <Container center>
      <Box my={10}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={isSmallerThanMd ? 3 : 6}
        >
          <Grid item xs={12} md={6} className={classes.leftColumn}>
            {/* Texts */}
            <Typography className={classes.heroTitleText} gutterBottom>
              <img src={PremiumLogo} alt="Aleph Beta Premium" className={classes.heroImage} />
            </Typography>

            <Typography variant="body1" className={classes.heroSubtitleText} gutterBottom>
              <strong>Take control of your Torah learning and invest in yourself. </strong>
              {lineFix(
                `With an Aleph Beta Premium Membership you gain unlimited access to hundreds of hours of entertaining Torah videos and engaging in-depth audios that offer you the fullest picture of the layer upon layer of analysis that goes into every piece of Torah. Plus, exclusive, live events, beautiful, printable Parsha guides... and so much more!`
              )}
            </Typography>

            <Typography variant="body1" className={classes.heroSubtitleText} gutterBottom>
              {lineFix(
                `Becoming a Premium member is the best way to support the work we do and spread meaningful Torah across the globe. So, sign up today and partner with us. Help us make an impact.`
              )}
            </Typography>

            {/* Price tag */}
            <PremiumSubscriptionPriceTag price={15} />

            {/* Checkout button */}
            <PremiumSubscriptionCheckoutButton title="Support Us" buttonSpacing={1} />

            {/* Tax deductible */}
            <PremiumSubscriptionNote />
          </Grid>

          <Grid item xs={12} md={6} className={classes.rightColumn}>
            <MediaPlayerWithAnimatedPlayButton url="https://clevertech.wistia.com/medias/36fs3v2qfe" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PremiumSubscriptionHero;
