import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { getNavigationPageLink } from '~utils/common-site-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Link from '~components/Link';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  subtitle: {
    marginLeft: '65px',
    fontSize: '12px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      textAlign: 'center'
    }
  },
  iconStyle: {
    fontSize: '25px',
    color: '#F1105A',
    marginRight: '12px'
  },
  premiumSubscribersLink: {
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));

const PremiumSubscriptionNote = props => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isLoggedIn } = session;
  return (
    <Box {...props}>
      <Box
        component={Typography}
        variant="subtitle1"
        pt={1}
        pb={2}
        className={classes.premiumSubscribersLink}
      >
        Already a Premium Member?{' '}
        <Link
          to={isLoggedIn ? '/subscribe' : getNavigationPageLink('/login', isMarketingSite)}
          underline="always"
        >
          Click here
        </Link>
      </Box>
      <Typography variant="subtitle1" className={classes.title}>
        <FavoriteIcon className={classes.iconStyle} /> All subscriptions are tax deductible
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        You can cancel anytime
      </Typography>
    </Box>
  );
};

export default PremiumSubscriptionNote;
