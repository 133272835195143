import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import AnimatedPlayButton from '~src/components/slices/about-hero/components/AnimatedPlayButton';
import MediaPlayer from '../media-player';

const MediaPlayerWithAnimatedPlayButton = ({ url, config }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleButtonVisibilityOnPlay = () => {
    setIsVisible(false);
  };
  const handleButtonVisibilityOnEnd = () => {
    setIsVisible(true);
    setIsPlaying(false);
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };
  return (
    <Box position="relative" width="100%">
      <Box maxWidth="600px" margin="0 auto">
        <MediaPlayer
          url={url}
          playing={isPlaying}
          config={config}
          onPlay={handleButtonVisibilityOnPlay}
          onEnded={handleButtonVisibilityOnEnd}
        />
      </Box>
      {isVisible && <AnimatedPlayButton onClick={handlePlayButtonClick} />}
    </Box>
  );
};
MediaPlayerWithAnimatedPlayButton.propTypes = {
  url: PropTypes.string.isRequired,
  config: PropTypes.shape({})
};

MediaPlayerWithAnimatedPlayButton.defaultProps = {
  config: {
    wistia: {
      options: {
        seo: false,
        videoFoam: true,
        playlistLinks: 'auto',
        playerColor: '#3688FF',
        resumable: false,
        playButton: false
      }
    }
  }
};

export default MediaPlayerWithAnimatedPlayButton;
