import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import { PremiumSubscriptionCheckoutButton } from '~modules/premium-subscription/components/shared';
import { lineFix } from '~modules/premium-subscription/premium-subscription.utils';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '28px',
      lineHeight: '24px'
    }
  },
  ulStyle: {
    paddingInlineStart: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(2)
  },
  liStyle: {
    paddingBottom: theme.spacing(3),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#666666',
    'list-style-type': 'disc'
  },
  imageMedia: {
    maxWidth: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  }
}));

const PremiumSubscriptionSection = ({ bgColor, direction, title, image, list }) => {
  const classes = useStyles();
  return (
    <Container center background={bgColor}>
      <Box py={6}>
        <Grid
          container
          direction={direction}
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <Box component="img" src={image.url} alt={image.alt} className={classes.imageMedia} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.heading}>
              {lineFix(title)}
            </Typography>

            <ul className={classes.ulStyle}>
              {list &&
                list.map(listItem => (
                  <li key={listItem} className={classes.liStyle}>
                    {listItem}
                  </li>
                ))}
            </ul>
            <PremiumSubscriptionCheckoutButton title="Support Us By Subscribing" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

PremiumSubscriptionSection.propTypes = {
  bgColor: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PremiumSubscriptionSection;
