import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  priceTagPosition: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  priceStyle: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '48px',
    textTransform: 'uppercase',
    color: '#438dce',
    margin: '15px 8px'
  }
}));

const SubscribePriceTag = ({ price, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={classes.priceTagPosition} {...rest}>
      <span className={classes.priceStyle}>${price}</span>
      <Typography variant="subtitle1">per month (billed yearly)</Typography>
    </Box>
  );
};

SubscribePriceTag.propTypes = {
  price: PropTypes.number.isRequired
};

export default SubscribePriceTag;
