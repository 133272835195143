import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import {
  PremiumSubscriptionPriceTag,
  PremiumSubscriptionCheckoutButton,
  PremiumSubscriptionNote
} from '~modules/premium-subscription/components/shared';
import AbLogoImage from './images/ab-logo.png';

const useStyles = makeStyles({
  imageMedia: {
    display: 'flex',
    maxWidth: '100%',
    marginRight: '8px'
  }
});

const PremiumSubscriptionCta = () => {
  const classes = useStyles();
  return (
    <Container center>
      <Box py={6}>
        <Box display="flex" alignItems="center">
          <img
            src={AbLogoImage}
            alt="Aleph Beta Logo"
            title="Aleph Beta"
            className={classes.imageMedia}
          />

          <Typography variant="h5" color="primary">
            PREMIUM
          </Typography>
        </Box>

        <PremiumSubscriptionPriceTag price={15} justifyContent="center" />

        <PremiumSubscriptionCheckoutButton title="Support Us By Subscribing" textAlign="center" />

        <PremiumSubscriptionNote ml={2} />
      </Box>
    </Container>
  );
};
export default PremiumSubscriptionCta;
